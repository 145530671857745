import './ClientProfileList.scss';
import {
  StructuredListBody,
  StructuredListCell,
  StructuredListRow,
  Button,
} from 'carbon-components-react';
import { Edit16 } from '@carbon/icons-react';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ExerciseAbility } from '../../types/adminPortalApiSchema';
import { WithBlueBullet } from '../Icons/BlueBullet';
import { StructuredListCellHeader } from '../BCStructuredList/BCStructuredList';

type ClientProfileListItem = {
  text: string;
  hide?: boolean;
  editable?: boolean;
  isDirty?: boolean;
  value: string | number | ReactElement | undefined | null;
  cycleId?: string | null;
  ability?: ExerciseAbility | null;
  cycleNumber?: number | null;
  daysToCheckIn?: number | null;
  initialWeight?: number | null;
};

type Props = {
  header: string;
  listItems: ClientProfileListItem[];
  editCycleModalCallback: (args: {
    cycleId: string;
    ability: ExerciseAbility;
    cycleNumber: number;
  }) => void;
  editDaysToCheckInCallback: (args: { daysToCheckIn: number }) => void;
  editInitialWeightCallback: (args: { initialWeight: number }) => void;
};

export const ClientProfileList = ({
  header,
  listItems,
  editCycleModalCallback,
  editDaysToCheckInCallback,
  editInitialWeightCallback,
}: Props): ReactElement => {
  return (
    <StructuredListBody>
      {header && (
        <StructuredListRow key={header}>
          <StructuredListCellHeader>{header}</StructuredListCellHeader>{' '}
          <StructuredListCellValue />
        </StructuredListRow>
      )}
      {listItems
        .filter((elem) => !elem.hide)
        .map((param, index) => {
          const {
            text,
            value,
            editable,
            isDirty,
            cycleId,
            ability,
            cycleNumber,
            daysToCheckIn,
            initialWeight,
          } = param;
          return (
            <StructuredListRow key={index}>
              <StructuredListCellName>
                {isDirty ? <WithBlueBullet copy={text} leftWidth={5} /> : text}
              </StructuredListCellName>
              <StructuredListCellValue $isDirty={isDirty}>
                {value}
                {editable && cycleId && ability && cycleNumber && (
                  <BottomPaddedButton
                    kind="ghost"
                    data-testid="edit-stat-icon"
                    iconDescription="icon"
                    renderIcon={Edit16}
                    onClick={(): void => {
                      editCycleModalCallback({ cycleId, ability, cycleNumber });
                    }}
                  />
                )}
                {editable && typeof daysToCheckIn === 'number' && (
                  <BottomPaddedButton
                    kind="ghost"
                    data-testid="edit-stat-icon"
                    iconDescription="icon"
                    renderIcon={Edit16}
                    onClick={(): void => {
                      editDaysToCheckInCallback({ daysToCheckIn });
                    }}
                  />
                )}
                {editable && typeof initialWeight === 'number' && (
                  <BottomPaddedButton
                    kind="ghost"
                    data-testid="edit-stat-icon"
                    iconDescription="icon"
                    renderIcon={Edit16}
                    onClick={(): void => {
                      editInitialWeightCallback({ initialWeight });
                    }}
                  />
                )}
              </StructuredListCellValue>
            </StructuredListRow>
          );
        })}
    </StructuredListBody>
  );
};

export const StructuredListCellValue = styled((props) => (
  <StructuredListCell {...props} />
)).attrs((props) => ({
  $isDirty: props.$isDirty || false,
}))<{ $isDirty?: boolean }>`
  font-weight: bold;
  width: 10em;
  padding-top: 2em;
  color: ${(props): string => {
    if (props.invalid) return props.theme.warningRed;
    return props.$isDirty ? props.theme.inspireCobalt : 'inherit';
  }};
`;

export const StructuredListCellName = styled((props) => (
  <StructuredListCell {...props} />
))`
  width: 10em;
  padding-top: 2em;
`;

export const BottomPaddedButton = styled((props) => <Button {...props} />)`
  margin-top: -1rem;
  float: right;
`;

export const TopPaddedButton = styled((props) => <Button {...props} />)`
  margin-top: -1rem;
  float: right;
  flexdirection: 'row';
`;
