import React, { ReactElement, useEffect, useState } from 'react';
import './styles.scss';
import AdminDataTable from './AdminDataTable';

import { fetchAdmins } from './getAdminQuery';
import styled from 'styled-components';
import { Loader } from '../Loader/Loader';
import { DisplayAdmin } from './utils';
import { Auth } from 'aws-amplify';

export const AdminTable = (): ReactElement => {
  const [rows, setRows] = useState<DisplayAdmin[]>([]);
  const [gqlerrors, setGQLErrors] = useState<[{ message: string }]>();
  const [userName, setUsername] = useState<string>('');

  const [isLoading, setLoading] = useState<boolean>(true);
  Auth.currentAuthenticatedUser().then((user) => {
    setUsername(user.username);
  });
  const searchAdmins = async (): Promise<void> => {
    const res = await fetchAdmins();
    const { data, errors } = res;
    if (errors) {
      setRows([]);
      setGQLErrors(errors);
    }
    if (data) {
      setRows(
        data.admins.map((entry) => {
          return {
            id: entry.id,
            email: entry.email,
            name: entry.name,
            group: entry.group,
            status: entry.status,
          } as DisplayAdmin;
        })
      );
    } else {
      setRows([]);
    }
  };

  function handleReloadData(): void {
    setLoading(true);
    searchAdmins().then(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    searchAdmins().then(() => setLoading(false));
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Wrapper>
          <AdminDataTable
            rows={rows}
            errors={gqlerrors}
            username={userName}
            onChange={handleReloadData}
          />
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  margin: 2rem auto auto;
  .bx--data-table td {
    vertical-align: middle;
  }
`;
