import Joi from '@hapi/joi';
import React, { ReactElement, useState } from 'react';

import { TextInput } from 'carbon-components-react';
import './styles.scss';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import { Form, Field } from 'react-final-form';
import { ErrorMessage } from '../../components/Theme/Theme';
import validate from '../../utils/validate';
import { StyledBlueButton } from '../../components/Button/Button';
import { CognitoUser } from '../../types/global';

export function MfaFrame(props: {
  user: undefined | CognitoUser;
}): ReactElement {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleLogin = ({ code }: { code: string }): void => {
    Auth.confirmSignIn(props.user, code, 'SOFTWARE_TOKEN_MFA').catch((err) => {
      setErrorMessage(`
          Something went wrong. Could not log in -
          ${err.message}
        `);
    });
  };

  return (
    <>
      <Form
        onSubmit={handleLogin}
        validate={(values) =>
          validate(
            values,
            Joi.object({
              code: Joi.string()
                .regex(/\d+/)
                .min(6)
                .max(6)
                .required()
                .messages({
                  'string.pattern.base': 'Code must be numbers only',
                }),
            })
          )
        }
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <IntroText>
              Verify <br />
              <SmallIntro>
                Enter validation code from Google Authenticator app
              </SmallIntro>
            </IntroText>
            <Field name="code">
              {({ input, meta }) => (
                <>
                  <InputWrapper>
                    <TextInput
                      {...input}
                      id="code"
                      invalid={meta.error && meta.touched}
                      invalidText={meta.error}
                      labelText="code"
                      light={false}
                      placeholder="Enter unique code"
                      size={undefined}
                      type="text"
                      data-cy="mfa-code-textfield"
                      autoComplete="one-time-code"
                      required
                    />
                  </InputWrapper>
                </>
              )}
            </Field>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <StyledConfirmButton
              data-cy="mfa-login-button"
              disabled={submitting}
              type="submit"
            >
              Log in
            </StyledConfirmButton>
          </form>
        )}
      />
      <div>
        <SmallOutro>Don&apos;t have the app?</SmallOutro>
        <OutroText>
          Download &quot;Google Authenticator&quot; on to your mobile device
        </OutroText>
      </div>
    </>
  );
}

export const StyledConfirmButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  margin: 64px auto auto;
  font-weight: bold;
  width: 100%;
`;

const InputWrapper = styled.div`
  margin-top: 30px;
`;
const SmallIntro = styled.span`
  margin-top: 20px;
  display: block;
  font-family: Avenir Next, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props): string => props.theme.l};
  line-height: 18px;
  letter-spacing: 0.16px;
`;

const IntroText = styled.h1`
  font-family: Avenir Next, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props): string => props.theme.l};
  line-height: 18px;
  letter-spacing: 0.16px;
  margin-top: 30px;
`;

const SmallOutro = styled.span`
  margin-top: 20px;
  display: block;
  font-family: Avenir Next, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props): string => props.theme.m};
  line-height: 18px;
  letter-spacing: 0.16px;
`;

const OutroText = styled.span`
  margin-top: 20px;
  display: block;
  font-family: Avenir Next, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props): string => props.theme.m};
  line-height: 18px;
  letter-spacing: 0.16px;
`;
