import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import { awsConfig } from './aws-config';
import { AuthHandler } from './AuthHandler';

Amplify.configure(awsConfig);
Auth.configure(awsConfig);

function renderApp(): void {
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <AuthHandler />
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

renderApp();
