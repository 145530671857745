import React, { Fragment } from 'react';
import styled from 'styled-components';
import { makeChallengeAttemptActive } from './ClientChallengeAttempts/queries';

interface Props {
  isDismissed: boolean;
  setIsLoading: (isLoading: boolean) => void;
  userId: string;
  challengeAttemptId: string;
  getData: () => Promise<void>;
}

const ChallengeStatus: React.FC<Props> = ({
  isDismissed,
  setIsLoading,
  userId,
  challengeAttemptId,
  getData,
}) => {
  const handleMakeChallengeActiveClick = async () => {
    setIsLoading(true);
    await makeChallengeAttemptActive(userId, challengeAttemptId);
    await getData();
    setIsLoading(false);
  };

  return (
    <Fragment>
      {isDismissed ? 'Dismissed' : 'Active'}
      {isDismissed && (
        <StyledActionButton onClick={handleMakeChallengeActiveClick}>
          Make active
        </StyledActionButton>
      )}
    </Fragment>
  );
};

const StyledActionButton = styled.button`
  cursor: pointer;
  background-color: ${(props): string => props.theme.inspireCobalt};
  color: #fff;
  border-radius: 4px;
  border: none;
  margin-left: 8px;
  padding: 4px;
`;

export default ChallengeStatus;
