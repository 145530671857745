import React from 'react';
import spacetime from 'spacetime';
import styled from 'styled-components';
import { UserBadge } from '../../types/adminPortalApiSchema';

type Props = {
  badges: (UserBadge & { title?: string })[];
};

const BadgesTable: React.FC<Props> = ({ badges }) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          <StyledTableHeader>Date awarded</StyledTableHeader>
          <StyledTableHeader>Badge</StyledTableHeader>
        </tr>
      </thead>
      <tbody>
        {badges.map((badge) => {
          return (
            <tr key={badge.badgeId}>
              <StyledTableCell>
                {spacetime(badge.awardedDate).format(
                  '{date-pad}/{iso-month}/{year} {hour-pad}:{minute-pad}'
                )}
              </StyledTableCell>
              <StyledTableCell>
                <a
                  href={`https://app.contentful.com/spaces/450abldm8aui/entries/${badge.badgeId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {badge.title || badge.badgeId}
                </a>
              </StyledTableCell>
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  border-collapse: collapse;
  margin-top: 16px;
`;

const StyledTableHeader = styled.th`
  text-align: left;
  font-weight: bold;
  padding: 8px;
`;

const StyledTableCell = styled.td`
  padding: 8px 16px;
`;
export default BadgesTable;
