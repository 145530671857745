import spacetime from 'spacetime';

import React, { FC } from 'react';
import { Subscription, Maybe } from '../../types/adminPortalApiSchema';
import styled from 'styled-components';
import Emoji from '../Emoji/emoji';

const SubscriptionInfo = styled.p`
  margin-bottom: 10px;
  font-size: 1.1rem;
`;

const Light = styled.span`
  margin-right: 5px;
  color: ${(props): string => props.theme.bodyCopyLight};
`;

const LifetimeUserSubscriptionDetails: FC<{
  subscription?: Subscription | null;
}> = ({ subscription }) => {
  return subscription?.currentProduct === 'rc_promo_standard_lifetime' ? (
    <span>Lifetime user: {subscription.currentProduct}</span>
  ) : (
    <></>
  );
};

const NonAnnualUserSubscriptionDetails: FC<{
  subscription?: Subscription | null;
}> = ({ subscription }) => {
  const isNonAnnual =
    subscription?.currentProduct !== 'rc_promo_standard_lifetime' &&
    (subscription?.length === 'MONTHLY' ||
      subscription?.length === 'QUARTERLY' ||
      subscription?.length === 'UNKNOWN');

  return isNonAnnual ? (
    <>
      <SubscriptionInfo>
        <Light>{`Current Product:`}</Light>
        <span>{subscription.currentProduct}</span>
      </SubscriptionInfo>
      <SubscriptionInfo>
        <Light>{`Subscription:`}</Light>
        <span>
          {subscription.isSubscriptionActive ? 'Active' : 'Expired'} (exp date:{' '}
          {spacetime(new Date(subscription.expiryDate)).format('numeric-uk')})
        </span>
      </SubscriptionInfo>
      <SubscriptionInfo>
        <Light>{`Autorenewal:`}</Light>
        <TickCrossEmoji isTrue={!!subscription.isAutoRenewal} />{' '}
      </SubscriptionInfo>
    </>
  ) : (
    <></>
  );
};

const AnnualUserSubscriptionDetails: FC<{
  subscription?: Subscription | null;
}> = ({ subscription }) => {
  return subscription?.length === 'ANNUAL' ? (
    <>
      <SubscriptionInfo>
        <Light>{`Current Product:`}</Light>
        <span>
          {subscription?.offerType !== 'UNKNOWN' &&
            `${subscription?.offerType.toLowerCase()} `}
          {subscription?.length.toLowerCase()} product (
          {subscription?.currentProduct})
        </span>
      </SubscriptionInfo>
      <SubscriptionInfo>
        <Light>{`Subscription:`}</Light>
        <span>
          {subscription.isSubscriptionActive ? 'Active' : 'Expired'}
          {subscription.expiryDate
            ? ` (exp date: ${spacetime(
                new Date(subscription.expiryDate)
              ).format('numeric-uk')})`
            : ' (no expiry date available)'}
        </span>
      </SubscriptionInfo>
      {subscription.expiryDate && (
        <SubscriptionInfo>
          <Light>Loyalty Window:</Light>
          <RenewalWindow subscription={subscription} />
        </SubscriptionInfo>
      )}
      <SubscriptionInfo>
        <Light>{`Autorenewal:`}</Light>
        <TickCrossEmoji isTrue={!!subscription.isAutoRenewal} />{' '}
      </SubscriptionInfo>
    </>
  ) : (
    <></>
  );
};

const StoreEmoji = ({
  store,
}: {
  store: 'APP_STORE' | 'PLAY_STORE' | 'PROMOTIONAL';
}) => {
  if (store === 'APP_STORE') return <Emoji label="app store" symbol="" />;
  if (store === 'PLAY_STORE') return <Emoji label="play store" symbol="🤖" />;
  if (store === 'PROMOTIONAL')
    return <Emoji label="body coach user" symbol="💙" />;
  return <></>;
};

const TickCrossEmoji = ({ isTrue }: { isTrue: boolean }) => {
  if (isTrue) return <Emoji label="yes" symbol="✅" />;
  return <Emoji label="no" symbol="❌" />;
};

const RenewalWindow = ({
  subscription,
}: {
  subscription?: Maybe<Subscription>;
}) => {
  if (!subscription) return <>No subscription</>;
  if (subscription.currentProduct === 'rc_promo_standard_lifetime')
    return <>Lifetime member</>;
  if (subscription.length !== 'ANNUAL')
    return <>Not Annual ({subscription.length})</>;
  if (subscription.store === 'PROMOTIONAL')
    return (
      <>
        B2B or Teachers user <StoreEmoji store={subscription.store} />
      </>
    );

  const today = spacetime.now();
  const expiry = spacetime(new Date(subscription.expiryDate));
  const windowOpen = expiry.subtract(15, 'day');
  const windowClose = expiry.add(30, 'day');

  if (windowClose.isBefore(today)) {
    return (
      <>
        <>closed {today.diff(windowClose, 'day')} days ago on </>
        <StoreEmoji store={subscription.store} />
      </>
    );
  }

  if (today.isBefore(windowOpen)) {
    return (
      <>
        <>opens in {today.diff(windowOpen, 'day')} days on </>
        <StoreEmoji store={subscription.store} />
      </>
    );
  }

  if (windowClose.isAfter(today)) {
    return (
      <>
        <>open, closes in {today.diff(windowClose, 'day')} days on </>
        <StoreEmoji store={subscription.store} />
      </>
    );
  }

  return <></>;
};

export {
  AnnualUserSubscriptionDetails,
  NonAnnualUserSubscriptionDetails,
  LifetimeUserSubscriptionDetails,
};
