import React, { ReactElement, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import {
  StyledBlueButton,
  StyledRedButton,
  StyledButton,
} from '../../Button/Button';
import CloseIcon from './x_close_icon.svg';
import { Select, TextInput } from 'carbon-components-react';
import { UserBodyStat } from '../../../types/adminPortalApiSchema';
import { getMeasurementLimits } from '../../ClientSections/ClientStatsSectionHelpers';
import { Loader } from '../../Loader/Loader';

type Props = {
  headline: string;
  confirmButtonText: string;
  updateUserStatsCallback: (bodyStats: UserBodyStat) => Promise<void>;
  dismissButtonText: string;
  loading: boolean;
  data: { bodyStat: UserBodyStat };
  dismissButtonCallback: () => void;
  dismissToPath?: string | null;
  confirmButtonColor: 'blue' | 'red';
};

export const EditStats = ({
  headline,
  data,
  loading,
  confirmButtonText,
  confirmButtonColor,
  updateUserStatsCallback,
  dismissToPath,
  dismissButtonText,
  dismissButtonCallback,
}: Props): ReactElement | null => {
  const dismissDialog: React.MouseEventHandler<HTMLImageElement> = (e) => {
    e.preventDefault();
    dismissButtonCallback();
  };

  const [value, changeValue] = useState(data.bodyStat.measurement);
  const { min, max } = getMeasurementLimits(data.bodyStat.measurementType);
  const confirmDialog: React.MouseEventHandler<HTMLImageElement> = async (
    e
  ) => {
    e.preventDefault();
    const newBodyStat = {
      ...data.bodyStat,
      measurement: value,
    };

    await updateUserStatsCallback(newBodyStat);
  };
  return (
    <ConfirmationTakeover>
      <ConfirmationWrapper>
        {loading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <>
            <CloseIconImg
              data-testid={'dismiss-x-icon'}
              onClick={dismissDialog}
              src={CloseIcon}
              alt="Close this dialog"
            ></CloseIconImg>
            <Heading>{headline}</Heading>
            <StyledNumberInput
              invalidText={`should be between ${min} and ${max}`}
              data-testid={'number-input'}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                const newVal = e.target.value
                  ? parseFloat(parseFloat(e.target.value).toFixed(2))
                  : NaN;
                changeValue(newVal);
              }}
              labelText=""
              disabled={false}
              type="number"
              id={`Edit Stats`}
              size={undefined}
              invalid={value < min || value > max}
              value={value as number}
            />
            <ButtonWrapper>
              <ConfirmButton
                confirmButtonColor={confirmButtonColor}
                data-testid={'confirm-button'}
                disabled={loading || value < min || value > max}
                onClick={confirmDialog}
                kind="secondary"
                size="default"
                tabIndex={0}
                type="button"
              >
                {confirmButtonText}
              </ConfirmButton>
              <DismissButton
                data-testid={'dismiss-button'}
                onClick={dismissDialog}
                kind="primary"
                size="default"
                tabIndex={0}
                type="button"
                href={dismissToPath}
              >
                {dismissButtonText}
              </DismissButton>
            </ButtonWrapper>
          </>
        )}
      </ConfirmationWrapper>
    </ConfirmationTakeover>
  );
};

export const StyledConfirmButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  margin: 0;
  font-weight: bold;
  width: 100%;
  margin: 10px 0px;
`;

const StyledNumberInput = styled((props) => <TextInput {...props} />)`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const StyledSelect = styled((props) => <Select {...props} />)`
  width: 100%;
  margin-bottom: ${(props): string => props.theme.contentMarginBottom};
`;

export const Padder = styled.div`
  margin-bottom: ${(props): string => props.theme.contentMarginBottom};
`;

export const ButtonPadder = styled.div`
  margin-bottom: 10px;
`;

const Heading = styled.h2`
  font-weight: normal;
  font-size: ${(props): string => props.theme.l};
  margin: 50px 0px;
`;

// We use z-index to make sure the takeover window sits above any other window content
const ConfirmationTakeover = styled.div`
  display: flex;
  z-index: ${(props): string => props.theme.confirmationTakeover};
  justify-content: center;
  align-items: center;
  background: rgba(57, 57, 57, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const ConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 20px 50px;
  border: 1px solid ${(props): string => props.theme.grey};
  border-radius: 15px;
  background: ${(props): string => props.theme.white};
  height: 430px;
  width: 352px;
  position: relative;
`;

const ConfirmButton = styled(({ confirmButtonColor, ...rest }) => {
  type Options = {
    blue: JSX.Element;
    red: JSX.Element;
  };

  const options: Options = {
    blue: <StyledBlueButton {...rest} />,
    red: <StyledRedButton {...rest} />,
  };

  return (
    options[confirmButtonColor as keyof Options] || (
      <StyledBlueButton {...rest} />
    )
  );
})`
  width: 100%;
  height: 48px;
`;

const DismissButton = styled((props) => <StyledButton {...props} />)`
  width: 100%;
  height: 48px;
  margin: 10px 0px 50px 0px;
`;

const CloseIconImg = styled.img`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;
