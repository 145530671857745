import { Auth } from 'aws-amplify';

export type GQLResponse<T> = Promise<{
  data: T;
  errors?: [{ message: string }];
}>;

export const queryAPI = <T>(
  query: string,
  variables?: { [key: string]: unknown }
): GQLResponse<T> => {
  return Auth.currentAuthenticatedUser().then((user) => {
    const token = user.signInUserSession.accessToken.jwtToken;
    return fetch(process.env.REACT_APP_API_URL || '', {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    })
      .then(
        (res) => {
          if (res.status === 401 || res.status === 403) {
            Auth.signOut();
          }
          return res.json();
        },
        (err) => {
          console.log('failed on appsync' + err.toString());
        }
      )
      .catch((err) => {
        console.log('CATCH ');
        console.log(err);
      });
  });
};
