/* eslint-disable graphql/template-strings */
import gql from 'fake-tag';
import { queryContentfulAPI } from '../../utils/contentfulApi';

function splitIntoBatches<T>(a: Array<T>, batchSize = 200): Array<Array<T>> {
  return Array.from(
    // Calculate the number of batches
    new Array(Math.ceil(a.length / batchSize)),
    /**
     * Grab the batch for each index from the original array (i.e. given a batch
     * size of 100 first grab 0-99, then 100-199, etc.).
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/slice
     */
    (_: string, i: number) => a.slice(i * batchSize, i * batchSize + batchSize)
  );
}

type Workout = {
  sys: {
    id: string;
  };
  name: string;
  internalName: string;
};

type Badge = {
  sys: {
    id: string;
  };
  title: string;
};

async function queryWorkouts(
  workoutIds: string[]
): Promise<{
  data: {
    workoutCollection: {
      items: Array<Workout>;
    };
    liveWorkoutCollection: {
      items: Array<Workout>;
    };
  };
  errors?: [{ message: string }];
}> {
  return await queryContentfulAPI(
    gql`
      query getWorkouts($workoutIds: [String!]) {
        workoutCollection(where: { sys: { id_in: $workoutIds } }) {
          items {
            sys {
              id
            }
            name
            internalName
          }
        }
        liveWorkoutCollection(where: { sys: { id_in: $workoutIds } }) {
          items {
            sys {
              id
            }
            name
            internalName
          }
        }
      }
    `,
    {
      workoutIds,
    }
  );
}

export async function fetchWorkouts(
  workoutIds: string[]
): Promise<{ workouts: Array<Workout>; liveWorkouts: Array<Workout> }> {
  const batchedWorkoutIds = splitIntoBatches(workoutIds);
  const workouts: Array<Workout> = [];
  const liveWorkouts: Array<Workout> = [];

  await Promise.all(
    batchedWorkoutIds.map(async (batch) => {
      const {
        data: { workoutCollection, liveWorkoutCollection },
      } = await queryWorkouts(batch);

      workouts.push(...workoutCollection.items);
      liveWorkouts.push(...liveWorkoutCollection.items);
    })
  );

  return { workouts, liveWorkouts };
}

async function queryBadges(
  badgeIds: string[]
): Promise<{
  data: {
    badgeCollection: {
      items: Array<Badge>;
    };
  };
  errors?: [{ message: string }];
}> {
  return await queryContentfulAPI(
    gql`
      query getBadges($badgeIds: [String!]) {
        badgeCollection(where: { sys: { id_in: $badgeIds } }) {
          items {
            sys {
              id
            }
            title
          }
        }
      }
    `,
    {
      badgeIds,
    }
  );
}

export async function fetchBadges(badgeIds: string[]): Promise<Array<Badge>> {
  const batchedBadgeIds = splitIntoBatches(badgeIds);
  const badges: Array<Badge> = [];

  await Promise.all(
    batchedBadgeIds.map(async (batch) => {
      const {
        data: { badgeCollection },
      } = await queryBadges(batch);

      badges.push(...badgeCollection.items);
    })
  );

  return badges;
}
