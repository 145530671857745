import { CycleSummary, ExerciseAbility } from '../types/adminPortalApiSchema';
import { queryAPI } from '../utils/queryApi';
import gql from 'fake-tag';

export async function fetchCycles(
  ability?: ExerciseAbility,
  cycleNumber?: number
): Promise<{
  data: { cycles: CycleSummary[] };
  errors?: [{ message: string }];
}> {
  return queryAPI<{
    cycles: CycleSummary[];
    errors: [{ message: string }];
  }>(
    gql`
      query GetCycles($ability: ExerciseAbility, $cycleNumber: Int) {
        cycles(ability: $ability, cycleNumber: $cycleNumber) {
          id
          ability
          cycleNumber
          workoutCount
        }
      }
    `,
    { ability, cycleNumber }
  );
}
