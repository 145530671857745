import { MealPlan, RecipePackSummary } from '../types/adminPortalApiSchema';
import { queryAPI } from '../utils/queryApi';
import gql from 'fake-tag';

export async function fetchRecipePacks(
  suitability?: MealPlan,
  sequence?: number
): Promise<{
  data: { recipePacks: RecipePackSummary[] };
  errors?: [{ message: string }];
}> {
  return queryAPI<{
    recipePacks: RecipePackSummary[];
    errors: [{ message: string }];
  }>(
    gql`
      query GetRecipePacks($suitability: MealPlan, $sequence: Int) {
        recipePacks(suitability: $suitability, sequence: $sequence) {
          id
          recipeCount
          snackCount
          generalCount
          refuelCount
          sequence
          suitability
          recipePackName
        }
      }
    `,
    { suitability, sequence }
  );
}
