import React, { Fragment, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { UserChallengeAttempt } from '../../types/adminPortalApiSchema';
import {
  Heading2,
  StyledTable,
  StyledTableCell,
  StyledTableHeader,
} from '../../components/Theme/Theme';
import { getChallengesAttempts } from './queries';
import { formatDateTime } from '../../utils/utils';
import Modal from '../../components/Modal/Modal';
import ViewClientChallengeAttempt from './ViewClientChallengeAttempt';
import { InlineLoading } from 'carbon-components-react';
import ChallengeStatus from '../ChallengeStatus';

interface Props {
  userId: string;
}

const ClientChallengeAttempts: React.FC<Props> = ({ userId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [challengeAttempts, setChallengeAttempts] = useState<
    UserChallengeAttempt[]
  >([]);
  const [selectedChallengeAttemptId, setSelectedChallengeAttemptId] = useState<
    string | undefined
  >();

  const getData = useCallback(async () => {
    setIsLoading(true);
    const res = await getChallengesAttempts(userId);
    setChallengeAttempts(res.challengeAttempts);
    setIsLoading(false);
  }, [userId]);

  useEffect(() => {
    getData();
  }, [userId, getData]);

  return (
    <ChallengeAttemptsWrapper>
      <div style={{ display: 'flex' }}>
        <Heading2>Challenge Attempts</Heading2>
        {isLoading && <InlineLoading status="active" />}
      </div>

      <StyledTable>
        <thead>
          <tr>
            <StyledTableHeader>Attempt ID</StyledTableHeader>
            <StyledTableHeader>Challenge</StyledTableHeader>
            <StyledTableHeader>Joined At</StyledTableHeader>
            <StyledTableHeader>Ends At</StyledTableHeader>
            <StyledTableHeader>Active?</StyledTableHeader>
          </tr>
        </thead>
        <tbody>
          {challengeAttempts.map((challengeAttempt) => {
            return (
              <tr key={challengeAttempt.id}>
                <StyledTableCell>
                  <StyledLinkButton
                    onClick={() =>
                      setSelectedChallengeAttemptId(challengeAttempt.id)
                    }
                  >
                    {challengeAttempt.id.slice(0, 6)}
                  </StyledLinkButton>
                </StyledTableCell>
                <StyledTableCell>{challengeAttempt.name}</StyledTableCell>
                <StyledTableCell>
                  {formatDateTime(challengeAttempt.joinedAt)}
                </StyledTableCell>
                <StyledTableCell>
                  {formatDateTime(challengeAttempt.endsAt)}
                </StyledTableCell>
                <StyledTableCell>
                  <ChallengeStatus
                    challengeAttemptId={challengeAttempt.id}
                    getData={getData}
                    setIsLoading={setIsLoading}
                    isDismissed={challengeAttempt.isDismissed}
                    userId={userId}
                  />
                </StyledTableCell>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
      <Modal
        isOpen={!!selectedChallengeAttemptId}
        onClose={() => setSelectedChallengeAttemptId(undefined)}
        isLarge={true}
      >
        <Fragment>
          {!!selectedChallengeAttemptId && (
            <ViewClientChallengeAttempt
              userId={userId}
              challengeAttemptId={selectedChallengeAttemptId}
              refreshAttemptList={getData}
            />
          )}
        </Fragment>
      </Modal>
    </ChallengeAttemptsWrapper>
  );
};

const ChallengeAttemptsWrapper = styled.div`
  padding: 50px;
  border-bottom: 1px solid ${(props): string => props.theme.grey};
  border-top: 1px solid ${(props): string => props.theme.grey};
  margin-top: 70px;
`;

const StyledLinkButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;
  color: ${(props): string => props.theme.inspireCobalt};
`;

export default ClientChallengeAttempts;
