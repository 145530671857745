import {
  StructuredListBody,
  StructuredListCell,
  StructuredListRow,
} from 'carbon-components-react';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

export interface BCStructuredListItem<T> {
  text: string;
  hide?: boolean;
  value: string | number | undefined | null;
  meta?: T;
}
interface Props {
  header?: string;
  params: BCStructuredListItem<unknown>[];
  onClick?: (item: BCStructuredListItem<unknown>) => void | Promise<void>;
}

export const BCStructuredList = ({
  params,
  header,
  onClick,
}: Props): ReactElement => {
  return (
    <StructuredListBody>
      {header && (
        <StructuredListRow key={header}>
          <StructuredListCellHeader>{header}</StructuredListCellHeader>{' '}
          <StructuredListCellValue />
        </StructuredListRow>
      )}
      {params
        .filter((elem) => !elem.hide)
        .map((param, index) => {
          return (
            <StructuredListRow
              onClick={onClick ? () => onClick(param) : undefined}
              key={index}
              className={onClick ? 'bc-list-item--selectable' : undefined}
            >
              <StructuredListCellName>{param.text}</StructuredListCellName>
              <StructuredListCellValue>{param.value}</StructuredListCellValue>
            </StructuredListRow>
          );
        })}
    </StructuredListBody>
  );
};

export const StructuredListCellValue = styled((props) => (
  <StructuredListCell {...props} />
))`
  font-weight: bold;
  padding-top: 2em;
  color: ${(props): string => {
    if (props.invalid) return props.theme.warningRed;
    return props.isDirty ? props.theme.inspireCobalt : 'inherit';
  }};
`;

export const StructuredListCellName = styled((props) => (
  <StructuredListCell {...props} />
))`
  width: 10em;
  padding-top: 2em;
`;

export const StructuredListCellHeader = styled((props) => (
  <StructuredListCell {...props} />
))`
  width: 10em;
  font-weight: bold;
  font-size: 1.5em;
`;
