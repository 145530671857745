import { queryAPI } from '../../utils/queryApi';

const query = /* GraphQL */ `
  mutation TrackWorkoutView(
    $userId: ID!
    $workoutId: ID!
    $workoutLength: Float!
  ) {
    trackWorkoutView(
      userId: $userId
      workoutId: $workoutId
      workoutLength: $workoutLength
    ) {
      fractionWatched
      secondsWatched
      workoutId
      hasPassedWatchedThreshold
      isEligibleForTracking
    }
  }
`;

export const trackWorkoutView = async (
  workoutId: string,
  workoutLength: number,
  userId: string
) => {
  const variables = {
    userId,
    workoutId,
    workoutLength,
  };

  await queryAPI(query, variables);
};
