import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useLocation,
} from 'react-router-dom';
import React, { ReactElement, ReactNode } from 'react';
import { Clients } from './pages/Clients';
import { Admins } from './pages/Admins/Admins';
import { Client } from './pages/Client/Client';
import { LoginPage } from './pages/Login/Login';
import { Template } from './Template';
import { ContentRecipePacks } from './pages/ContentRecipePacks/ContentRecipePacks';
import { ContentRecipePack } from './pages/ContentRecipePack/ContentRecipePack';
import { Content } from './pages/Content/Content';
import { ContentInvalidate } from './pages/ContentInvalidate/ContentInvalidate';
import { Theme } from './components/Theme/Theme';

export function Routes(props: { isLoggedIn: boolean }): ReactElement {
  const { isLoggedIn } = props;
  return (
    <Switch>
      <Route exact path="/login">
        <Theme>
          <LoginPage isLoggedIn={isLoggedIn} />
        </Theme>
      </Route>
      <Template isLoggedIn={isLoggedIn}>
        {/* Temp redirect from old path */}
        <PrivateRoute exact path="/clients" isLoggedIn={isLoggedIn}>
          <Redirect to="/" />
        </PrivateRoute>
        <PrivateRoute path="/clients/:id" isLoggedIn={isLoggedIn}>
          <Client />
        </PrivateRoute>
        <PrivateRoute exact path="/content" isLoggedIn={isLoggedIn}>
          <Content />
        </PrivateRoute>
        <PrivateRoute exact path="/content/recipepacks" isLoggedIn={isLoggedIn}>
          <ContentRecipePacks />
        </PrivateRoute>
        <PrivateRoute exact path="/content/invalidate" isLoggedIn={isLoggedIn}>
          <ContentInvalidate />
        </PrivateRoute>
        <PrivateRoute path="/content/recipepacks/:id" isLoggedIn={isLoggedIn}>
          <ContentRecipePack />
        </PrivateRoute>
        <PrivateRoute exact path="/" isLoggedIn={isLoggedIn}>
          <Clients />
        </PrivateRoute>
        <PrivateRoute exact path="/admin" isLoggedIn={isLoggedIn}>
          <Admins />
        </PrivateRoute>
      </Template>
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
}

function NoMatch(): ReactElement {
  const location = useLocation();
  return (
    <div>
      <h1>404</h1>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

type PrivateRouteProps = {
  children: ReactNode;
  isLoggedIn: boolean;
} & Omit<RouteProps, 'render'>;

function PrivateRoute({
  children,
  isLoggedIn,
  ...rest
}: PrivateRouteProps): ReactElement {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
