import React, { ReactElement, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import {
  StyledBlueButton,
  StyledRedButton,
  StyledButton,
} from '../../Button/Button';
import CloseIcon from './x_close_icon.svg';
import { TextInput } from 'carbon-components-react';
import { Loader } from '../../Loader/Loader';
import { emailIsValid as checkEmailValidity } from '../../../utils/utils';
import {
  updateClientEmail,
  UpdateClientEmailResult,
} from '../../ClientSections/updateClientEmail';

type Props = {
  headline: string;
  confirmButtonText: string;
  updateEmailCallback: (email: string) => void;
  dismissButtonText: string;
  data: { userId: string; email: string };
  dismissButtonCallback: () => void;
  dismissToPath?: string | null;
  confirmButtonColor: 'blue' | 'red';
};

export const EditEmail = ({
  headline,
  data,
  confirmButtonText,
  confirmButtonColor,
  updateEmailCallback,
  dismissToPath,
  dismissButtonText,
  dismissButtonCallback,
}: Props): ReactElement | null => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const dismissDialog: React.MouseEventHandler<HTMLImageElement> = (e) => {
    e.preventDefault();
    dismissButtonCallback();
  };

  const [emailValue, setEmailValue] = useState(data.email);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [updateEmailResult, setUpdateEmailResult] = useState<
    false | UpdateClientEmailResult
  >(false);

  return (
    <ConfirmationTakeover>
      <ConfirmationWrapper>
        {isLoading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        {!isLoading && updateEmailResult && !updateEmailResult.loginChanged && (
          <>
            <Heading>Something went wrong...</Heading>
            <WarningMessage>{updateEmailResult.message}</WarningMessage>
            <DismissButton
              data-testid={'dismiss-button'}
              onClick={dismissDialog}
              kind="primary"
              size="default"
              tabIndex={0}
              type="button"
              href={dismissToPath}
            >
              Continue
            </DismissButton>
          </>
        )}
        {!isLoading && updateEmailResult && updateEmailResult.loginChanged && (
          <>
            <Heading>Email updated</Heading>
            <p>The client can now login with their new email.</p>
            <SuccessMessage>{updateEmailResult.message}</SuccessMessage>
            <DismissButton
              data-testid={'dismiss-button'}
              onClick={dismissDialog}
              kind="primary"
              size="default"
              tabIndex={0}
              type="button"
              href={dismissToPath}
            >
              Continue
            </DismissButton>
          </>
        )}
        {!isLoading && !updateEmailResult && (
          <>
            <CloseIconImg
              data-testid={'dismiss-x-icon'}
              onClick={dismissDialog}
              src={CloseIcon}
              alt="Close this dialog"
            ></CloseIconImg>
            <Heading>{headline}</Heading>
            <SelectRow>
              <StyledTextInput
                invalidText={`Email not a valid email`}
                data-testid={'email-input'}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  setEmailValue(e.target.value);

                  setEmailIsValid(checkEmailValidity(e.target.value));
                }}
                labelText="Email"
                type="email"
                id="Edit Email"
                invalid={!emailIsValid}
                min={0}
                value={emailValue}
              />
            </SelectRow>
            <ButtonWrapper>
              <ConfirmButton
                confirmButtonColor={confirmButtonColor}
                data-testid={'confirm-button'}
                disabled={isLoading || !emailIsValid}
                onClick={async () => {
                  if (emailIsValid && emailValue !== data.email) {
                    setLoading(true);
                    const updateEmailResult = await updateClientEmail(
                      data.userId,
                      emailValue
                    );
                    setUpdateEmailResult(updateEmailResult);
                    if (updateEmailResult.loginChanged) {
                      updateEmailCallback(emailValue);
                    }
                    setLoading(false);
                  }
                }}
                kind="secondary"
                size="default"
                tabIndex={0}
                type="button"
              >
                {confirmButtonText}
              </ConfirmButton>
              <DismissButton
                data-testid={'dismiss-button'}
                onClick={dismissDialog}
                kind="primary"
                size="default"
                tabIndex={0}
                type="button"
                href={dismissToPath}
              >
                {dismissButtonText}
              </DismissButton>
            </ButtonWrapper>
          </>
        )}
      </ConfirmationWrapper>
    </ConfirmationTakeover>
  );
};

export const StyledConfirmButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  margin: 0;
  font-weight: bold;
  width: 100%;
  margin: 10px 0px;
`;

const StyledTextInput = styled((props) => <TextInput {...props} />)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  input[type='text'] {
    color: ${(props): string => {
      if (!props.valid) return props.theme.red;
      return props.isDirty ? props.theme.inspireCobalt : 'inherit';
    }};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Padder = styled.div`
  margin-bottom: ${(props): string => props.theme.contentMarginBottom};
`;

export const ButtonPadder = styled.div`
  margin-bottom: 10px;
`;

const Heading = styled.h2`
  font-weight: normal;
  font-size: ${(props): string => props.theme.l};
  margin: 50px 0px;
`;

const WarningMessage = styled.p`
  background-color: ${(props): string => props.theme.recoveryBlush};
  padding: 1rem;
`;

const SuccessMessage = styled.p`
  background-color: ${(props): string => props.theme.vigourZest};
  padding: 1rem;
`;

// We use z-index to make sure the takeover window sits above any other window content
const ConfirmationTakeover = styled.div`
  display: flex;
  z-index: ${(props): string => props.theme.confirmationTakeover};
  justify-content: center;
  align-items: center;
  background: rgba(57, 57, 57, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const ConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 20px 50px;
  border: 1px solid ${(props): string => props.theme.grey};
  border-radius: 15px;
  background: ${(props): string => props.theme.white};
  height: 430px;
  width: 422px;
  position: relative;
`;

const ConfirmButton = styled(({ confirmButtonColor, ...rest }) => {
  type Options = {
    blue: JSX.Element;
    red: JSX.Element;
  };

  const options: Options = {
    blue: <StyledBlueButton {...rest} />,
    red: <StyledRedButton {...rest} />,
  };

  return (
    options[confirmButtonColor as keyof Options] || (
      <StyledBlueButton {...rest} />
    )
  );
})`
  width: 100%;
  height: 48px;
`;

const DismissButton = styled((props) => <StyledButton {...props} />)`
  width: 100%;
  height: 48px;
  margin: 10px 0px 50px 0px;
`;

const CloseIconImg = styled.img`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

const SelectRow = styled.div`
  display: flex;
  flex-direction: row;
`;
