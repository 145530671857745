import React, {
  ReactElement,
  useState,
  ChangeEvent,
  SetStateAction,
} from 'react';
import styled from 'styled-components';
import {
  StyledBlueButton,
  StyledRedButton,
  StyledButton,
} from '../../Button/Button';
import CloseIcon from './x_close_icon.svg';
import { Select, SelectItem, TextInput } from 'carbon-components-react';
import { ExerciseAbility } from '../../../types/adminPortalApiSchema';
import { Loader } from '../../Loader/Loader';
import {
  capitalize,
  convertAbilityForEmail,
  convertFromEmailAbilityToAbility,
} from '../../../utils/utils';
import { fetchCycles } from '../../../queries/getCyclesQuery';
import { ExerciseAbilityForEmail } from '../../../types/global';

type CycleData = {
  ability: ExerciseAbility;
  cycleNumber: number;
};

interface CycleDataCallback extends CycleData {
  ensureCycleContentIsAvailable: () => Promise<boolean>;
}

type Props = {
  headline: string;
  confirmButtonText: string;
  updateCycleCallback: (arg0: CycleDataCallback) => Promise<void>;
  dismissButtonText: string;
  loading: boolean;
  data: { ability: ExerciseAbility; cycleNumber: number; cycleId: string };
  dismissButtonCallback: () => void;
  dismissToPath?: string | null;
  confirmButtonColor: 'blue' | 'red';
};

export const EditCycle = ({
  headline,
  data,
  loading,
  confirmButtonText,
  confirmButtonColor,
  updateCycleCallback,
  dismissToPath,
  dismissButtonText,
  dismissButtonCallback,
}: Props): ReactElement | null => {
  const dismissDialog: React.MouseEventHandler<HTMLImageElement> = (e) => {
    e.preventDefault();
    dismissButtonCallback();
  };

  const cycleOptions: ExerciseAbility[] = [
    'BEGINNER',
    'INTERMEDIATE',
    'ADVANCED',
    'LIFESTYLE',
    'GRADUATE',
  ];

  const [abilityValue, setAbilityValue] = useState<ExerciseAbilityForEmail>(
    convertAbilityForEmail(data.ability)
  );
  const [invalidCycleData, setInvalidCycleData] = useState(false);
  const [cycleNumberValue, setCycleNumberValue] = useState(data.cycleNumber);
  const confirmDialog: React.MouseEventHandler<HTMLImageElement> = async (
    e
  ) => {
    e.preventDefault();
    const ensureCycleContentIsAvailable = async () => {
      const res = await fetchCycles();
      const { data, errors } = res;
      if (errors || !data) {
        console.log(errors);
        setInvalidCycleData(true);
        return false;
      } else {
        const cycleIsValid = Boolean(
          data.cycles.find(
            (c) =>
              c.ability === abilityValue && c.cycleNumber === cycleNumberValue
          )
        );
        setInvalidCycleData(!cycleIsValid);
        return cycleIsValid;
      }
    };
    await updateCycleCallback({
      ability: convertFromEmailAbilityToAbility(abilityValue),
      cycleNumber: cycleNumberValue,
      ensureCycleContentIsAvailable,
    });
  };
  return (
    <ConfirmationTakeover>
      <ConfirmationWrapper>
        {loading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <>
            <CloseIconImg
              data-testid={'dismiss-x-icon'}
              onClick={dismissDialog}
              src={CloseIcon}
              alt="Close this dialog"
            ></CloseIconImg>
            <Heading>{headline}</Heading>
            <SelectGroup>
              <StyledSelect
                invalid={false}
                invalidText="You must choose a Plan Level."
                id="ability"
                name="ability"
                labelText="Plan"
                aria-label="Plan"
                data-testid={'newadmin-group-field'}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  setAbilityValue(
                    e.target.value as SetStateAction<ExerciseAbilityForEmail>
                  );
                }}
              >
                {cycleOptions
                  .map((ability, key) => (
                    <SelectItem
                      key={key}
                      value={convertAbilityForEmail(ability)}
                      defaultChecked={abilityValue === ability}
                      text={capitalize(ability.toLowerCase())}
                    />
                  ))
                  .sort((a) => {
                    return a.props.defaultChecked ? -1 : 1;
                  })}
              </StyledSelect>
              <StyledNumberInput
                invalidText={''}
                data-testid={'number-input'}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  setInvalidCycleData(false);
                  const newVal = e.target.value
                    ? parseInt(parseFloat(e.target.value).toFixed(0))
                    : NaN;
                  setCycleNumberValue(newVal || 1);
                }}
                labelText="Cycle"
                disabled={false}
                type="number"
                id={`Edit Cycle Number`}
                size={undefined}
                invalid={invalidCycleData}
                value={cycleNumberValue}
              />
            </SelectGroup>
            {invalidCycleData && (
              <InvalidWarning>
                {`${capitalize(
                  abilityValue.toLowerCase()
                )} cycle ${cycleNumberValue} does not exist. Please try another cycle number.`}
              </InvalidWarning>
            )}
            <ButtonWrapper>
              <ConfirmButton
                confirmButtonColor={confirmButtonColor}
                data-testid={'confirm-button'}
                disabled={loading || invalidCycleData}
                onClick={confirmDialog}
                kind="secondary"
                size="default"
                tabIndex={0}
                type="button"
              >
                {confirmButtonText}
              </ConfirmButton>
              <DismissButton
                data-testid={'dismiss-button'}
                onClick={dismissDialog}
                kind="primary"
                size="default"
                tabIndex={0}
                type="button"
                href={dismissToPath}
              >
                {dismissButtonText}
              </DismissButton>
            </ButtonWrapper>
          </>
        )}
      </ConfirmationWrapper>
    </ConfirmationTakeover>
  );
};

export const StyledConfirmButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  margin: 0;
  font-weight: bold;
  width: 100%;
  margin: 10px 0px;
`;

const StyledNumberInput = styled((props) => <TextInput {...props} />)`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const StyledSelect = styled((props) => <Select {...props} />)`
  width: 70%;
  margin-bottom: ${(props): string => props.theme.contentMarginBottom};
  margin-right: 10px;
`;

export const Padder = styled.div`
  margin-bottom: ${(props): string => props.theme.contentMarginBottom};
`;

export const ButtonPadder = styled.div`
  margin-bottom: 10px;
`;

const Heading = styled.h2`
  font-weight: normal;
  font-size: ${(props): string => props.theme.l};
  margin: 50px 0px;
`;

// We use z-index to make sure the takeover window sits above any other window content
const ConfirmationTakeover = styled.div`
  display: flex;
  z-index: ${(props): string => props.theme.confirmationTakeover};
  justify-content: center;
  align-items: center;
  background: rgba(57, 57, 57, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const ConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 20px 50px;
  border: 1px solid ${(props): string => props.theme.grey};
  border-radius: 15px;
  background: ${(props): string => props.theme.white};
  height: 430px;
  width: 422px;
  position: relative;
`;

const ConfirmButton = styled(({ confirmButtonColor, ...rest }) => {
  type Options = {
    blue: JSX.Element;
    red: JSX.Element;
  };

  const options: Options = {
    blue: <StyledBlueButton {...rest} />,
    red: <StyledRedButton {...rest} />,
  };

  return (
    options[confirmButtonColor as keyof Options] || (
      <StyledBlueButton {...rest} />
    )
  );
})`
  width: 100%;
  height: 48px;
`;

const DismissButton = styled((props) => <StyledButton {...props} />)`
  width: 100%;
  height: 48px;
  margin: 10px 0px 50px 0px;
`;

const CloseIconImg = styled.img`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

const SelectGroup = styled.div`
  display: flex;
`;

const InvalidWarning = styled.p`
  color: props.theme.warningRed;
  text-align: left;
  margin-bottom: 1em;
`;
