import { UserChallengeAttempt } from '../../types/adminPortalApiSchema';
import { queryAPI } from '../../utils/queryApi';

const getChallengeAttemptsQuery = /* GraphQL */ `
  query($userId: ID!) {
    challengeAttempts(id: $userId) {
      id
      challengeId
      name
      joinedAt
      endsAt
      isDismissed
    }
  }
`;

export const getChallengesAttempts = async (userId: string) => {
  const res = await queryAPI<{ challengeAttempts: UserChallengeAttempt[] }>(
    getChallengeAttemptsQuery,
    { userId }
  );
  return res.data;
};

const getChallengeAttemptQuery = /* GraphQL */ `
  query($userId: ID!, $challengeAttemptId: ID!) {
    challengeAttempt(id: $userId, challengeAttemptId: $challengeAttemptId) {
      id
      challengeId
      name
      joinedAt
      endsAt
      isDismissed
      stages {
        id
        name
        endsAt
        startsAt
        startsAt
        items {
          id
          itemId
          itemName
          itemType
          completedAt
        }
      }
    }
  }
`;

export const getChallengeAttempt = async (
  userId: string,
  challengeAttemptId: string
) => {
  const res = await queryAPI<{ challengeAttempt: UserChallengeAttempt }>(
    getChallengeAttemptQuery,
    { userId, challengeAttemptId }
  );
  return res.data;
};

const makeChallengeAttemptActiveMutation = /* GraphQL */ `
  mutation($userId: ID!, $challengeAttemptId: ID!) {
    makeChallengeAttemptActive(
      id: $userId
      challengeAttemptId: $challengeAttemptId
    )
  }
`;

export const makeChallengeAttemptActive = async (
  userId: string,
  challengeAttemptId: string
) => {
  await queryAPI(makeChallengeAttemptActiveMutation, {
    userId,
    challengeAttemptId,
  });
};

const extendChallengeAttemptMutation = /* GraphQL */ `
  mutation($userId: ID!, $challengeAttemptId: ID!) {
    extendChallengeAttempt(id: $userId, challengeAttemptId: $challengeAttemptId)
  }
`;

export const extendChallengeAttempt = async (
  userId: string,
  challengeAttemptId: string
) => {
  await queryAPI(extendChallengeAttemptMutation, {
    userId,
    challengeAttemptId,
  });
};
