import React, { ReactElement, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import {
  StyledBlueButton,
  StyledRedButton,
  StyledButton,
} from '../../Button/Button';
import CloseIcon from './x_close_icon.svg';
import { TextInput } from 'carbon-components-react';
import { Loader } from '../../Loader/Loader';
import { getMeasurementLimits } from '../../ClientSections/ClientStatsSectionHelpers';
import { Scalars } from '../../../types/adminPortalApiSchema';

type Props = {
  headline: string;
  confirmButtonText: string;
  updateInitialWeightCallback: ({
    initialWeight,
  }: {
    initialWeight: number;
  }) => Promise<void>;
  dismissButtonText: string;
  loading: boolean;
  data: { initialWeight: Scalars['Float'] };
  dismissButtonCallback: () => void;
  dismissToPath?: string | null;
  confirmButtonColor: 'blue' | 'red';
};

export const dateInDaysFromNow = (dayCount: number): string => {
  const now = new Date();
  return new Date(now.setDate(now.getDate() + dayCount)).toLocaleDateString();
};

const { min, max } = getMeasurementLimits('WEIGHT');

export const EditInitialWeight = ({
  headline,
  data,
  loading,
  confirmButtonText,
  confirmButtonColor,
  updateInitialWeightCallback,
  dismissToPath,
  dismissButtonText,
  dismissButtonCallback,
}: Props): ReactElement | null => {
  const dismissDialog: React.MouseEventHandler<HTMLImageElement> = (e) => {
    e.preventDefault();
    dismissButtonCallback();
  };

  const [initialWeightValue, setInitialWeightValue] = useState(
    data.initialWeight
  );

  const confirmDialog: React.MouseEventHandler<HTMLImageElement> = async (
    e
  ) => {
    e.preventDefault();
    updateInitialWeightCallback({ initialWeight: initialWeightValue });
  };
  return (
    <ConfirmationTakeover>
      <ConfirmationWrapper>
        {loading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <>
            <CloseIconImg
              data-testid={'dismiss-x-icon'}
              onClick={dismissDialog}
              src={CloseIcon}
              alt="Close this dialog"
            ></CloseIconImg>
            <Heading>{headline}</Heading>
            <SelectRow>
              <StyledNumberInput
                invalidText={`Weight should be between ${min} and ${max}`}
                data-testid={'number-input'}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  const newWeight = e.target.value
                    ? parseFloat(parseFloat(e.target.value).toFixed(2))
                    : NaN;
                  setInitialWeightValue(newWeight);
                }}
                labelText="Initial weight (kg)"
                disabled={false}
                type="number"
                id={`Edit Initial weight`}
                size={undefined}
                invalid={initialWeightValue < min || initialWeightValue > max}
                value={initialWeightValue as number}
              />
            </SelectRow>
            <ButtonWrapper>
              <ConfirmButton
                confirmButtonColor={confirmButtonColor}
                data-testid={'confirm-button'}
                disabled={
                  loading ||
                  initialWeightValue < min ||
                  initialWeightValue > max
                }
                onClick={confirmDialog}
                kind="secondary"
                size="default"
                tabIndex={0}
                type="button"
              >
                {confirmButtonText}
              </ConfirmButton>
              <DismissButton
                data-testid={'dismiss-button'}
                onClick={dismissDialog}
                kind="primary"
                size="default"
                tabIndex={0}
                type="button"
                href={dismissToPath}
              >
                {dismissButtonText}
              </DismissButton>
            </ButtonWrapper>
          </>
        )}
      </ConfirmationWrapper>
    </ConfirmationTakeover>
  );
};

export const StyledConfirmButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  margin: 0;
  font-weight: bold;
  width: 100%;
  margin: 10px 0px;
`;

const StyledNumberInput = styled((props) => <TextInput {...props} />).attrs(
  (props) => ({
    $isDirty: props.$isDirty || false,
  })
)<{ $isDirty?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  input[type='number'] {
    color: ${(props): string => {
      if (props.invalid) return props.theme.warningRed;
      return props.$isDirty ? props.theme.inspireCobalt : 'inherit';
    }};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Padder = styled.div`
  margin-bottom: ${(props): string => props.theme.contentMarginBottom};
`;

export const ButtonPadder = styled.div`
  margin-bottom: 10px;
`;

const Heading = styled.h2`
  font-weight: normal;
  font-size: ${(props): string => props.theme.l};
  margin: 50px 0px;
`;

// We use z-index to make sure the takeover window sits above any other window content
const ConfirmationTakeover = styled.div`
  display: flex;
  z-index: ${(props): string => props.theme.confirmationTakeover};
  justify-content: center;
  align-items: center;
  background: rgba(57, 57, 57, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const ConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 20px 50px;
  border: 1px solid ${(props): string => props.theme.grey};
  border-radius: 15px;
  background: ${(props): string => props.theme.white};
  height: 430px;
  width: 422px;
  position: relative;
`;

const ConfirmButton = styled(({ confirmButtonColor, ...rest }) => {
  type Options = {
    blue: JSX.Element;
    red: JSX.Element;
  };

  const options: Options = {
    blue: <StyledBlueButton {...rest} />,
    red: <StyledRedButton {...rest} />,
  };

  return (
    options[confirmButtonColor as keyof Options] || (
      <StyledBlueButton {...rest} />
    )
  );
})`
  width: 100%;
  height: 48px;
`;

const DismissButton = styled((props) => <StyledButton {...props} />)`
  width: 100%;
  height: 48px;
  margin: 10px 0px 50px 0px;
`;

const CloseIconImg = styled.img`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

const SelectRow = styled.div`
  display: flex;
  flex-direction: row;
`;
