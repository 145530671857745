import { queryAPI } from '../../utils/queryApi';

const updateUserEmailMutation = /* GraphQL */ `
  mutation($userId: ID!, $newEmail: String!) {
    updateUserEmail(id: $userId, newEmail: $newEmail) {
      loginChanged
      message
    }
  }
`;

export type UpdateClientEmailResult = {
  loginChanged: boolean;
  message: string;
};

type UpdateClientEmailResponse = {
  updateUserEmail: UpdateClientEmailResult;
  errors?: [{ message: string }];
};

async function updateClientEmailQuery(
  userId: string,
  newEmail: string
): Promise<UpdateClientEmailResult> {
  const result = await queryAPI<UpdateClientEmailResponse>(
    updateUserEmailMutation,
    {
      userId,
      newEmail,
    }
  );
  if (result.data?.updateUserEmail) {
    return result.data.updateUserEmail;
  }
  let message = 'Client email failed to update for an unexpected reason';
  if (result.errors && result.errors.length > 0) {
    message = result.errors[0].message;
  }
  return {
    loginChanged: false,
    message: message,
  };
}

export const updateClientEmail = async (
  userId: string,
  newEmail: string
): Promise<UpdateClientEmailResult> => {
  try {
    return await updateClientEmailQuery(userId, newEmail);
  } catch (err) {
    return {
      loginChanged: false,
      message: 'Email did not update correctly, please contact an admin',
    };
  }
};
