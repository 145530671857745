import gql from 'fake-tag';
import { queryAPI } from '../../utils/queryApi';
import {
  ClientDetail,
  TrackedWorkout,
  UserBadge,
} from '../../types/adminPortalApiSchema';
import { fetchBadges, fetchWorkouts } from './getClientQueryContent';

type ContentRichClientDetail = {
  trackedWorkouts: (TrackedWorkout & { name?: string })[];
  badges: (UserBadge & { title?: string })[];
} & ClientDetail;

export async function fetchClient(
  id: string
): Promise<{
  data: { client: ClientDetail | ContentRichClientDetail };
  errors?: [{ message: string }];
}> {
  const response = await queryAPI<{ client: ClientDetail }>(
    gql`
      query GetClient($id: ID!) {
        client(id: $id) {
          email
          id
          subscription
          name
          sex
          currentSubscription {
            revenuecatId
            expiryDate
            store
            isRefunded
            isRenewed
            isAutoRenewal
            isSubscriptionActive
            periodType
            offerType
            currentProduct
            length
          }
          subscriptionBillingFrequency
          createdAt
          initialWeight {
            measurement
            id
            timestamp
          }
          macros {
            fat
            carb
            meat
            veg
            water
            poultry
            fish
            sauce
          }
          cycles {
            ability
            bodyStats {
              measurement
              measurementType
            }
            completed
            cycleCreatedAt
            cycleId
            cycleNumber
            cycleUpdatedAt
            mealPlan
            recipePackNumber
          }
          dob
          height {
            measurement
          }
          trackedWorkouts {
            sessionId
            workoutId
            workoutIsWatched
            timestamp
            trainer
            trainingType
            fractionWatched
            secondsWatched
            workoutDuration
            rawData
          }
          badges {
            badgeId
            awardedDate
          }
        }
      }
    `,
    { id: id }
  );

  if (!response || response?.errors?.length) {
    return response;
  }

  const { data, errors } = response;

  const workoutIds = Array.from(
    new Set(data.client.trackedWorkouts?.map((workout) => workout.workoutId))
  );

  const badgeIds = Array.from(
    new Set(data.client.badges?.map((badge) => badge.badgeId))
  );

  const { workouts, liveWorkouts } = await fetchWorkouts(workoutIds);
  const badges = await fetchBadges(badgeIds);

  data.client.badges = data.client.badges?.map((badge) => ({
    ...badge,
    title: badges.find(({ sys: { id } }) => id === badge.badgeId)?.title,
  }));

  data.client.trackedWorkouts = data.client.trackedWorkouts?.map(
    (trackedWorkout) => ({
      ...trackedWorkout,
      name:
        workouts.find(({ sys: { id } }) => id === trackedWorkout.workoutId)
          ?.internalName ??
        liveWorkouts.find(({ sys: { id } }) => id === trackedWorkout.workoutId)
          ?.internalName,
    })
  );

  return { data, errors };
}
