import React, { ReactElement, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  StyledRedButton,
  StyledBlueButton,
  StyledButton,
} from '../../Button/Button';
import InfoSVG from './circled_i_icon.svg';
import WarningSVG from './warning_icon.svg';

import CloseIcon from './x_close_icon.svg';
import { Loader } from '../../Loader/Loader';

type Props = {
  warning: boolean;
  headline: string;
  byline: string;
  confirmButtonText: string;
  confirmButtonColor: 'blue' | 'red';
  confirmButtonCallback: () => Promise<void>;
  continueToPath?: string | null; // if null is passed (or prop isn't passed), clicking the confirmButton will not redirect the user away from the current page.
  dismissButtonText: string;
  dismissButtonCallback: () => void;
  dismissToPath?: string | null;
};

export const ConfirmationDialog = ({
  warning,
  headline,
  byline,
  confirmButtonText,
  confirmButtonColor,
  confirmButtonCallback,
  continueToPath,
  dismissToPath,
  dismissButtonText,
  dismissButtonCallback,
}: Props): ReactElement | null => {
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    return (): void => {
      setLoading(false);
    };
  }, []);

  const confirmDialog: React.MouseEventHandler<HTMLImageElement> = async (
    e
  ) => {
    e.preventDefault();
    setLoading(true);
    await confirmButtonCallback();
  };
  const dismissDialog: React.MouseEventHandler<HTMLImageElement> = (e) => {
    e.preventDefault();
    dismissButtonCallback();
  };
  return (
    <ConfirmationTakeover>
      <ConfirmationWrapper>
        {loading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <>
            <CloseIconImg
              data-testid={'dismiss-x-icon'}
              onClick={dismissDialog}
              src={CloseIcon}
              alt="Close this dialog"
            ></CloseIconImg>
            <InfoIconImg
              src={warning ? WarningSVG : InfoSVG}
              alt={headline}
            ></InfoIconImg>
            <Heading style={{ paddingBottom: '10px', paddingTop: '10px' }}>
              {headline}
            </Heading>
            <p>{byline}</p>
            <ButtonWrapper>
              <ConfirmButton
                confirmButtonColor={confirmButtonColor}
                data-testid={'confirm-button'}
                onClick={confirmDialog}
                href={continueToPath}
                kind="secondary"
                size="default"
                tabIndex={0}
                type="button"
              >
                {confirmButtonText}
              </ConfirmButton>
              <DismissButton
                data-testid={'dismiss-button'}
                onClick={dismissDialog}
                kind="primary"
                size="default"
                tabIndex={0}
                type="button"
                href={dismissToPath}
              >
                {dismissButtonText}
              </DismissButton>
            </ButtonWrapper>
          </>
        )}
      </ConfirmationWrapper>
    </ConfirmationTakeover>
  );
};

const ConfirmationTakeover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(57, 57, 57, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const ConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 20px 50px;
  border: 1px solid ${(props): string => props.theme.grey};
  border-radius: 15px;
  background: ${(props): string => props.theme.white};
  height: 465px;
  width: 352px;
  position: relative;
`;

const ConfirmButton = styled(({ confirmButtonColor, ...rest }) => {
  type Options = {
    blue: JSX.Element;
    red: JSX.Element;
  };

  const options: Options = {
    blue: <StyledBlueButton {...rest} />,
    red: <StyledRedButton {...rest} />,
  };

  return (
    options[confirmButtonColor as keyof Options] || (
      <StyledBlueButton {...rest} />
    )
  );
})`
  width: 100%;
  height: 48px;
`;

const DismissButton = styled((props) => <StyledButton {...props} />)`
  width: 100%;
  height: 48px;
  margin: 10px 0px 50px 0px;
`;

const CloseIconImg = styled.img`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

const InfoIconImg = styled.img`
  padding-top: 50px;
  width: 74px;
  align-self: center;
`;

const Heading = styled.h2`
  font-weight: normal;
  font-size: ${(props): string => props.theme.l};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
