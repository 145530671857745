import { InlineLoading } from 'carbon-components-react';
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { UserChallengeAttempt } from '../../types/adminPortalApiSchema';
import { formatDateTime } from '../../utils/utils';
import ChallengeStatus from '../ChallengeStatus';
import { getChallengeAttempt, extendChallengeAttempt } from './queries';

interface Props {
  userId: string;
  challengeAttemptId: string;
  refreshAttemptList: () => Promise<void>;
}

const ViewClientChallengeAttempt: React.FC<Props> = ({
  userId,
  challengeAttemptId,
  refreshAttemptList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [challengeAttempt, setChallengeAttempt] = useState<
    UserChallengeAttempt | undefined
  >();

  const getData = useCallback(async () => {
    setIsLoading(true);
    const res = await getChallengeAttempt(userId, challengeAttemptId);
    setChallengeAttempt(res.challengeAttempt);
    setIsLoading(false);
  }, [challengeAttemptId, userId]);

  const refresh = async () => {
    await getData();
    await refreshAttemptList();
  };

  const handleExtendChallengeClick = async () => {
    setIsLoading(true);
    await extendChallengeAttempt(userId, challengeAttemptId);
    await refresh();
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [userId, challengeAttemptId, getData]);

  return (
    <div>
      {isLoading && <InlineLoading status="active" />}
      {!!challengeAttempt && (
        <Fragment>
          <StyledHeader>{challengeAttempt.name}</StyledHeader>
          <StyledMetaContainer>
            <StyledMetaValue>Attempt ID: {challengeAttempt.id}</StyledMetaValue>
            <StyledMetaValue>
              Joined at: {formatDateTime(challengeAttempt.joinedAt)}
            </StyledMetaValue>
            <StyledMetaValue>
              Ends at: {formatDateTime(challengeAttempt.endsAt)}
            </StyledMetaValue>
            <StyledMetaValue>
              <ChallengeStatus
                challengeAttemptId={challengeAttempt.id}
                getData={refresh}
                setIsLoading={setIsLoading}
                isDismissed={challengeAttempt.isDismissed}
                userId={userId}
              />
              {!challengeAttempt.isDismissed && (
                <StyledActionButton onClick={handleExtendChallengeClick}>
                  Extend by 7 days
                </StyledActionButton>
              )}
            </StyledMetaValue>
          </StyledMetaContainer>
          <StyledSubHeader>Stages:</StyledSubHeader>
          {challengeAttempt.stages.map((stage) => {
            return (
              <StyledStageContainer key={stage.id}>
                <StyledStageName>{stage.name}</StyledStageName>
                <StyledMetaContainer>
                  <StyledMetaValue>
                    Starts: {formatDateTime(stage.startsAt)}
                  </StyledMetaValue>
                  <StyledMetaValue>
                    Ends: {formatDateTime(stage.endsAt)}
                  </StyledMetaValue>
                </StyledMetaContainer>
                <h4>Stage Items:</h4>
                {stage.items.map((item) => {
                  return (
                    <StyledStageItemsContainer key={item.id}>
                      <p>
                        {item.itemName} [{item.itemId}] (
                        {item.itemType.toLowerCase()}
                        {', '}
                        {item.completedAt
                          ? `completed: ${formatDateTime(item.completedAt)} ✅`
                          : 'not completed ❌'}
                        )
                      </p>
                    </StyledStageItemsContainer>
                  );
                })}
              </StyledStageContainer>
            );
          })}
        </Fragment>
      )}
    </div>
  );
};

const StyledHeader = styled.h3`
  font-weight: bold;
  font-size: 32px;
`;

const StyledSubHeader = styled.h4`
  font-weight: bold;
  font-size: 18px;
`;

const StyledMetaContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const StyledMetaValue = styled.p`
  margin-bottom: 8px;
`;

const StyledStageContainer = styled.div`
  margin-top: 16px;
  padding: 8px;
`;

const StyledStageName = styled.p`
  font-weight: bold;
  font-size: 16px;
`;

const StyledStageItemsContainer = styled.ul`
  padding: 8px;
  list-style-type: none;
  display: flex;
`;

const StyledActionButton = styled.button`
  cursor: pointer;
  background-color: ${(props): string => props.theme.inspireCobalt};
  color: #fff;
  border-radius: 4px;
  border: none;
  margin-left: 8px;
  padding: 4px;
`;

export default ViewClientChallengeAttempt;
