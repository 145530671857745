import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import { Link, useLocation } from 'react-router-dom';
import {
  HeaderMenu,
  HeaderMenuItem,
  HeaderNavigation,
} from 'carbon-components-react';
import './UserAuth.scss';
type Props = {
  isLoggedIn: boolean;
};

export const UserAuth = (props: Props): ReactElement => {
  const { isLoggedIn } = props;
  const [userName, setUsername] = useState<string>('');

  const isLoginPage = useLocation().pathname === '/login';

  const handleLogout = (): void => {
    if (isLoggedIn) {
      Auth.signOut();
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      Auth.currentAuthenticatedUser().then((user) => {
        setUsername(user.username);
      });
    }
  }, [isLoggedIn]);

  return (
    <Wrapper>
      {!isLoginPage && !isLoggedIn && <Link to={'/login'}> Login</Link>}
      {isLoggedIn && (
        <StyledHeaderNavigation aria-label="User dropdown">
          <HeaderMenu
            aria-label={userName}
            menuLinkName={userName}
            className="navDropdown"
          >
            <HeaderMenuItem
              href="#"
              onClick={handleLogout}
              className="navItem"
              data-cy="auth-logout-item"
            >
              Log out
            </HeaderMenuItem>
          </HeaderMenu>
        </StyledHeaderNavigation>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 100px;
`;

export const StyledHeaderNavigation = styled(({ ...rest }) => (
  <HeaderNavigation {...rest} />
))`
  float: right;
  display: block;
`;
