import { createGlobalStyle, css } from 'styled-components';
import { colors } from '../components/Theme/colors';
import './fonts.scss';

export const globalStyles = css`
  body {
    font-family: 'Lato', sans-serif;
    display: inherit;
    overflow-y: scroll;
    font-size: 0.9rem;
    color: ${colors.bodyCopy};
  }

  .bc-list-item--selectable {
    cursor: pointer;
    transition: background-color 300ms ease-in-out;
  }

  .bc-list-item--selectable:hover {
    background-color: rgba(57, 57, 57, 0.3);
  }
`;
export const GlobalStyles = createGlobalStyle`${globalStyles}`;
